import React, {useState} from 'react';
import {Form, Select, Input, Button, notification} from "antd";
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";
import Result from "../../components/Result";
import CryptoJS from "crypto-js";

const Home = () => {
    // var encrypted = CryptoJS.TripleDES.encrypt(JSON.stringify("Mostafa Mojtahedi"), "Secret Passphrase").toString();
    // var bytes = CryptoJS.TripleDES.decrypt(encrypted, "Secret Passphrase");
    // const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    // console.log(encrypted, decrypted)
    // states
    const [result, setResult] = useState("")
    // form hook
    const [form] = Form.useForm()
    const formProps = {
        form,
        name: "form",
        onFinish(values) {
            try {
                switch (values.operation) {
                    case "encryption":
                        const encrypted = CryptoJS.TripleDES.encrypt(JSON.stringify(values.text), values.key).toString();
                        setResult(encrypted);
                        notification.success({
                            message: "Text encrypted successfully!"
                        })
                        return;
                    case "decryption":
                        const decryptedBytes = CryptoJS.TripleDES.decrypt(values.text, values.key);
                        setResult(JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8)));
                        notification.success({
                            message: "Text decrypted successfully!"
                        })
                        return;
                    default:
                        notification.error({
                            message: "Something went wrong!!"
                        })
                }
            } catch (e) {
                notification.error({
                    message: "Something Wrong!!",
                    description: e.message
                })
            }
        },
        onFinishFailed(values) {
            console.log(values);
            notification.error({
                message: "Please provide required data"
            })
        },
        requiredMark: true,
        layout: "horizontal",
        labelCol: { span: 3 },
        wrapperCol: { span: 21 },
    }
    return (
        <>
            <div className="container">
                <h1>MOSTAFA MOJTAHEDI ENCRYPTOR</h1>
                <Form {...formProps}>
                    <Form.Item name="operation" label="Operation" labelAlign={"left"} rules={[{ required: true }]}>
                        <Select placeholder="Select a option and change input text above">
                            <Select.Option value="encryption">Encryption</Select.Option>
                            <Select.Option value="decryption">Decryption</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name={'text'} label={"Text"} labelAlign={"left"} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name={'key'} label={"Key"} labelAlign={"left"} rules={[{ required: true }]}>
                        <Input.Password iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}/>
                    </Form.Item>
                    <Button type="primary" htmlType="submit" block={true}>Submit</Button>
                </Form>
                {result && (
                    <Result result={result} />
                )}
            </div>
        </>
    );
};

export default Home;