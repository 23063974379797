import React, {useRef} from 'react';
import {Button, Form, Input, notification} from "antd";

const Result = ({result}) => {
    // ref hook
    const textareaRef = useRef(null);
    // copy to clipboard handler
    const clickButtonHandler = () => {
        // const text = document.getElementById("textarea");
        const text = textareaRef.current.resizableTextArea.textArea;
        // select text
        text.select();
        text.setSelectionRange(0, 99999999); // for mobile
        // copy text
        document.execCommand("copy");
        notification.success({
            message: "Text copied to clipboard.",
            description: text.value
        })
    }
    return (
        <>
            <h3>Result:</h3>
            <Input.TextArea ref={textareaRef} value={result} />
            <Button type="primary" htmlType="button" block={true} onClick={clickButtonHandler}>Copy</Button>
        </>
    );
};

export default Result;

