import React from 'react';
import Home from "./pages/Home/Home";


const App = () => {
    return (
        <>
            <Home />
        </>
    );
};

export default App;